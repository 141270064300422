<template>
  <div id="app" v-title data-title="518skins-更懂你的CSGO开箱平台">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import Utils from "./assets/js/util.js";
import { EXPIRE_TIME } from "@/config";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
      userInfo: {},
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    let _this = this;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    _this.userInfo = userInfo;
    _this.getUserInfo();
    Utils.$on("pid", function (pid) {
      _this.userInfo.id = pid;
      _this.getUserInfo();
    });
  },
  created() {
    this.$store.commit("setMobile", this.isMobile());
    window.addEventListener("resize", () => {
      this.$store.commit("setMobile", this.isMobile());
    });
  },
  methods: {
    isMobile() {
      let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      return flag;
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    getUserInfo() {
      let param = {
        player_id: this.userInfo ? this.userInfo.id : "",
      };
      if (param.player_id) {
        this.$axios
          .post("/index/User/getPlayerInfo", this.$qs.stringify(param))
          .then((res) => {
            if (res.data.status == 1 && res.data.data) {
              const { total_amount, state } = res.data.data;
              if (total_amount && state) {
                Utils.$emit("money", total_amount);
                Utils.$emit("state", state);
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify({
                    ...res.data.data,
                    expire_time: new Date().getTime() + EXPIRE_TIME,
                  })
                );
              }
            }
          });
      }
    },
  },
  components: {},
};
</script>

<style lang="less">
@import "./assets/css/media.less";

.hxd-iconfont,
.iconfont {
  transition: all 0.1s linear;
  background: linear-gradient(90deg, #e9b10e, #e9b10e);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 18px;
}

div:active {
  background-color: transparent; /* 设置点击时的背景色为透明 */
  outline: none; /* 取消点击时的默认外边框样式 */
}

/* 滚动条 */
::-webkit-scrollbar-thumb:horizontal {
  /*水平滚动条的样式*/
  width: 4px;
  background-color: #cccccc;
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滚动条的背景颜色*/
  -webkit-border-radius: 0; /*滚动条的圆角宽度*/
}
::-webkit-scrollbar {
  width: 10px; /*滚动条的宽度*/
  height: 8px; /*滚动条的高度*/
}
::-webkit-scrollbar-thumb:vertical {
  /*垂直滚动条的样式*/
  height: 50px;
  background-color: #e9b10e;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  /*滚动条的hover样式*/
  height: 50px;
  background-color: #e9b10e;
}

//改
div,
blockquote,
body,
html,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

html,
body,
#app {
  //  height: 100%;
  height: calc(~"100vh - 75px");
  //  height: calc(~"100vh");
  font-family: 微软雅黑;
  // overflow-y: auto;
}
body::-webkit-scrollbar {
  display: none;
}
.clear {
  clear: both;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
html /deep/ .v-modal {
  top: 60px;
}

//邮箱弹框 样式修改
html /deep/ .el-message-box {
  background-color: #333542;
  border: none;
  .el-message-box__title {
    color: #c3c3e2;
  }
}
</style>
